/* <input type=date> does not show placeholders. If we have one with the specified selector, then flip it to <input
   type=text> when it's blank so that the placeholder shows.
 */
import { attachDynamicEvent, whenSelectorPresentAll } from '../helpers/dom'

const SELECTOR = "input[data-date-placeholder='1']"

const dateOnLoad = function (el) {
  if (el.type == 'date' && el.value == '') {
    el.type = 'text'
  }
}

const dateOnFocus = function (_event) {
  this.type = 'date'
}

const dateOnBlur = function (_event) {
  dateOnLoad(this)
}

// 'focus' is not bubbling and so doesn't work with attachDynamicEvent
attachDynamicEvent(SELECTOR, 'focusin', dateOnFocus)
attachDynamicEvent(SELECTOR, 'focusout', dateOnBlur)
whenSelectorPresentAll(SELECTOR, dateOnLoad)
